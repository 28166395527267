import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/gracias/HeroMain"
const Gracias = (props) => (
  <Layout>
    <Seo  
    title="Gracias TeleSAN" 
    />
    <Hero/>
  </Layout>
);

export default Gracias;

